import { Injectable } from '@angular/core';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Constants } from '../../../providers/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  constants = Constants;
  public token: string;

  constructor(
    private firebaseX: FirebaseX,
    private platform: Platform,
  ) { }

  public async getToken(): Promise<string> {
    // console.log(this.platform.is('android'));
    // console.log(this.platform.is('ios'));
    if (this.platform.is('android')) {
      this.token = await this.firebaseX.getToken();
    } else if (this.platform.is('ios')) {
      this.token = await this.firebaseX.getToken();
      await this.firebaseX.grantPermission();
    } else {
      // console.log('soy la web');
    }
    localStorage.setItem(this.constants.localStorage.tokenDispositivo, this.token);
    // console.log('------------------getToken-----------------');
    // console.log(this.token);
    return this.token;
  }

  public onMessageReceived(): Observable<any> {
    return this.firebaseX.onMessageReceived();
  }
}
