import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
// SET LANGUAGE
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
import localeEsExtra from '@angular/common/locales/extra/es-CO';
// redux
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effectsArray } from './store/effects';
import { appReducers } from './store/app.reducers';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { CalendarModule } from 'ion2-calendar';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';


// import { FCM } from '@ionic-native/fcm/ngx';
// import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from './components/components.module';
import { UserfilterPageModule } from './pages/modals/userfilter/userfilter.module';
import { CancelappointmentPageModule } from './pages/modals/cancelappointment/cancelappointment.module';
import { PipesModule } from './pipes/pipes.module';
import { environment } from '../environments/environment';

registerLocaleData(localeEs, 'es-ES', localeEsExtra);
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ComponentsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        UserfilterPageModule,
        CancelappointmentPageModule,
        PipesModule,
        CalendarModule,
        ChartModule,
        HighchartsChartModule,
        StoreModule.forRoot(appReducers),
        EffectsModule.forRoot(effectsArray),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        }),
    ],
    providers: [
        StatusBar,
        CurrencyPipe,
        Keyboard,
        CallNumber,
        SplashScreen,
        // FirebaseAnalytics,
        // FCM,
        FirebaseX,
        Device,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
