import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NavController, } from '@ionic/angular';
import { ModalController, NavParams } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import * as moment from 'moment';

import { Constants } from '../../../../providers/constants/constants';
import { ToolsService } from './../../../service/tools/tools.service';
import { LoadingService } from './../../../service/loading/loading.service';
import { AppointmentService } from './../../../service/appointment/appointment.service';

@Component({
  selector: 'app-cancelappointment',
  templateUrl: './cancelappointment.page.html',
  styleUrls: ['./cancelappointment.page.scss'],
})
export class CancelappointmentPage implements OnInit {
  constants = Constants;
  idUserSesion = JSON.parse(localStorage.getItem(this.constants.localStorage.user))._id;
  nameCustomer: string;
  lastNameCustomer: string;
  nameAttendAppointment: string;
  lastNameAttendAppointment: string;
  nameCreatorAppointment: string;
  lastCreatorAppointment: string;
  noteAppointment: string;
  typeOfAppointment: string;
  idAppointmentCancel: number;
  dateAppointment: string;
  validations_form: FormGroup;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public navController: NavController,
    public formBuilder: FormBuilder,
    public keyboard: Keyboard,
    public toolsService: ToolsService,
    public loading: LoadingService,
    public appointmentService: AppointmentService
  ) { }

  ngOnInit() {
    this.validatorsFormCancelAppointment();
    this.nameCustomer = this.navParams.data.paramUser.usuario.nombres;
    this.lastNameCustomer = this.navParams.data.paramUser.usuario.apellidos;
    this.nameAttendAppointment = this.navParams.data.paramUser.citas.usuario_atendedor.nombres;
    this.lastNameAttendAppointment = this.navParams.data.paramUser.citas.usuario_atendedor.apellidos;
    this.nameCreatorAppointment = this.navParams.data.paramUser.citas.usuario_agendador.nombres;
    this.lastCreatorAppointment = this.navParams.data.paramUser.citas.usuario_agendador.apellidos;
    this.noteAppointment = this.navParams.data.paramUser.citas.nota;
    this.typeOfAppointment = this.navParams.data.paramUser.citas.tipos.nombre;
    this.idAppointmentCancel = this.navParams.data.paramUser.citas._id;
    const horaFin = (this.navParams.data.paramUser.citas.fecha_fin) ? ` a ${moment(this.navParams.data.paramUser.citas.fecha_fin).format('h:mm A')}` : '';
    this.dateAppointment = `${this.toolsService.parseDates(this.navParams.data.paramUser.citas.fecha, 1)} ${horaFin}`;
  }

  validatorsFormCancelAppointment() {
    this.validations_form = this.formBuilder.group({
      descriptionCancelAppointment: ['', [Validators.required]]
    });
  }

  async closeModal(refresh) {
    await this.modalController.dismiss(refresh);
  }

  /**
 * @description Se hace llamado al web services que cancela la cita
 */
  cancelAppointment() {
    const data = { 'nota': this.validations_form.get('descriptionCancelAppointment').value.trim() };
    this.keyboard.hide();
    this.loading.present(this.constants.loading.messages.createdAppointment, this.constants.loading.spinner);
    this.appointmentService.putCancelAppointment(this.idAppointmentCancel, data)
      .subscribe({
        next: (response) => {
          this.loading.dismiss();
          const typeResponse = response['type'];
          if (typeResponse === this.constants.services.types.success) {
            this.toolsService.presentToast(this.constants.appointment.notificationCancel);
            this.validations_form.reset();
            this.closeModal(true);
          } else if (typeResponse === this.constants.services.types.error) {
            this.toolsService.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
            setTimeout(() => {
              this.toolsService.presentToast('No se pudo notificar al cliente, pero si se cancelo la cita, adquiere más notificaciones.', 7000);
            }, 3000);
          }
        }, error: (err) => {
          this.loading.dismiss();
          this.toolsService.statusService(err);
          this.loading.dismiss();
        }
      });
  }
}
