export const Constants = {
  versioApp: '1.0.40',
  origenNewCita: ['app_movil', 'app_web', 'autoasignacion_web'],
  contactAgendate: {
    salesTeam: {
      nameKeyTeam: 'salesTeam',
      email: 'administracion@agendateapp.com',
      cellPhoneNumber: 3166232885,
      whatsapp: 3052898203
    },
    supportTeam: {
      nameKeyTeam: 'supportTeam',
      email: 'administracion@agendateapp.com',
      cellPhoneNumber: 3166232885,
      whatsapp: 3052898203
    }
  },
  customYearValues: [ 2030, 2029, 2028, 2027, 2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017],
  ionCalendarFormat: {
    weekdays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthPickerFormat: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  },
  state: {
    active: 'activo',
    inactive: 'inactivo',
    delete: 'eliminar'
  },
  roles: {
    administrador: 'administrador',
    asistente: 'asistente',
    atiendecitas: 'atiendecitas',
    paciente: 'paciente'
  },
  users: {
    titleCreated: 'Crear usuario',
    titleUpdate: 'Editar usuario',
    notificationCreated: 'Usuario creado.',
    notificationUpdate: 'Usuario editado.',
    notificationDelete: 'Usuario eliminado.',
    types: {
      patient: 'paciente',
      attendAppointments: 'atiendecitas',
      admin: 'administrador',
    }
  },
  procedures: {
    titleCreated: 'Crear procedimiento',
    titleUpdate: 'Editar procedimiento',
    notificationCreated: 'procedimiento creado.',
    notificationUpdate: 'procedimiento editado.',
    notificationDelete: 'procedimiento eliminado.'
  },
  appointment: {
    titleNewAppointment: 'Nueva cita',
    titleUpdateAppointment: 'Editar cita',
    notificationCreated: 'Cita creada.',
    notificationUpdate: 'Cita editada.',
    notificationCancel: 'Cita cancelada.',
    notificationForward: 'Cita reenviada.',
    notificacionAtendida: 'Cita atendida.',
  },
  agenda: {
    notificationCreated: 'Agenda creada.',
    notificationUpdate: 'Agenda editada.',
  },
  timeProcedures: [
    { label: '15 Min', value: 15 },
    { label: '20 Min', value: 20 },
    { label: '30 Min', value: 30 },
    { label: '40 Min', value: 40 },
    { label: '45 Min', value: 45 },
    { label: '1 Hora', value: 60 },
    { label: '1 Hora 15 Min', value: 75 },
    { label: '1 Hora 30 Min', value: 90 },
    { label: '1 Hora 45 Min', value: 105 },
    { label: '2 Horas', value: 120 },
    { label: '2 Horas 15 Min', value: 135 },
    { label: '2 Horas 30 Min', value: 150 },
    { label: '2 Horas 45 Min', value: 165 },
    { label: '3 Horas', value: 180 },
    { label: '3 Horas 15 Min', value: 195 },
    { label: '3 Horas 30 Min', value: 210 },
    { label: '3 Horas 45 Min', value: 225 },
    { label: '4 Horas', value: 240 },
    { label: '4 Horas 15 Min', value: 255 },
    { label: '4 Horas 30 Min', value: 270 },
    { label: '4 Horas 45 Min', value: 285 },
    { label: '5 Horas', value: 300 },
    { label: '5 Horas 15 Min', value: 315 },
    { label: '5 Horas 30 Min', value: 330 },
    { label: '5 Horas 45 Min', value: 345 },
    { label: '6 Horas', value: 360 },
    { label: '6 Horas 15 Min', value: 375 },
    { label: '6 Horas 30 Min', value: 390 },
    { label: '6 Horas 45 Min', value: 405 },
    { label: '7 Horas', value: 420 }
  ],
  verificationCodePhoneNumber: {
    minAndMaxCode: 'El código de verificación debe ser de 6 dígitos.'
  },
  inactivateUrlNavigationMenu: ['/', undefined, '/login', '/registeraccount'],
  localStorage: {
    isSession: 'isSession',
    token: 'token',
    user: 'user',
    initAppSlider: 'initAppSlider',
    tokenDispositivo: 'tokenDispositivo'
  },
  validationMessages: {
    isNAn: [
      { type: 'invalid', message: 'El campo debe ser numérico.' }
    ],
    tipoRol: [
      { type: 'required', message: 'El tipo de usuario es obligatorio.' },
    ],
    nombres: [
      { type: 'required', message: 'El nombre es obligatorio.' },
      { type: 'minlength', message: 'El nombre debe de tener minimo 4 caracteres.' },
      { type: 'maxlength', message: '' },
      { type: 'pattern', message: '' }
    ],
    email: [
      { type: 'required', message: 'El correo electrónico es obligatorio.' },
      { type: 'email', message: 'Por favor ingrese un correo electrónico válido.' }
    ],
    tipoIdentificacion: [
      { type: 'required', message: 'El tipo de identificación es obligatorio.' },
    ],
    identificacion: [
      { type: 'required', message: 'El numero de identificación es obligatorio.' },
    ],
    password: [
      { type: 'required', message: 'La contraseña es obligatoria.' },
      { type: 'minlength', message: 'La contraseña debe tener al menos 5 caracteres.' },
      { type: 'pattern', message: 'Su contraseña debe contener al menos una mayúscula, una minúscula y un número.' }
    ],
    confirm_password: [
      { type: 'required', message: 'La contraseña de confirmación es obligatoria.' }
    ],
    matching_passwords: [
      { type: 'areEqual', message: 'Las contraseñas no coinciden.' }
    ],
    celulares: [
      { type: 'required', message: 'El numero de celular es obligatorio.' },
      { type: 'minlength', message: 'El numero de celular debe de ser de 10 caracteres.' },
      { type: 'maxlength', message: 'El numero de celular debe de ser de 10 caracteres.' }
    ],
    telefonos: [
      { type: 'required', message: 'El numero de télefono fijo es obligatorio.' },
      { type: 'minlength', message: 'El numero de télefono fijo debe de ser de 7 caracteres.' },
      { type: 'maxlength', message: 'El numero de télefono fijo debe de ser de 7 caracteres.' }
    ],
    nombreEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    nitEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    direccionEmpresa: [
      { type: 'required', message: 'La dirección de la empresa es obligatoria.' },
    ],
    tipoServicioEmpresa: [
      { type: 'required', message: 'El tipo de servicio es obligatorio.' },
    ],
    tipoDePlan: [
      { type: 'required', message: 'El tipo de plan es obligatorio.' },
    ],
    usuarioAtendedor: [
      { type: 'required', message: 'Quien atiende la cita es obligatorio.' },
    ],
    tipoCita: [
      { type: 'required', message: 'Tipo de cita es obligatoria.' },
    ],
    fecha: [
      { type: 'required', message: 'La fecha es obligatoria.' },
    ],
    fechaHora: [
      { type: 'required', message: 'La hora es obligatoria.' },
    ],
    tiempoEstimado: [
      { type: 'required', message: 'El tiempo estimado es obligatorio.' },
      { type: 'maxlength', message: 'El tiempo máximo no debe superar los 3 caracteres.' },
      { type: 'pattern', message: 'No se permiten caracteres especiales.' }
    ],
    nota: [
      { type: 'required', message: 'La nota es obligatoria.' },
    ],
    descriptionCancelAppointment: [
      { type: 'required', message: 'El motivo de la cancelación es obligatorio.' },
    ],
    code: [
      { type: 'required', message: 'El código de de recuperación es obligatorio.' },
    ],
    requiredHour: [
      { type: 'required', message: 'La hora es obligatoria.' }
    ],
    startTimeIsInvalid: [
      { type: 'required', message: 'La hora de inicio del turno debe de ser menor a la hora final del turno.' }
    ],
    endTimeIsInvalid: [
      { type: 'required', message: 'La hora de inicio del segundo turno debe de ser mayor a la hora final del primer turno.' }
    ],
    agendaUserOrOrganization: [
      { type: 'required', message: 'La empresa o personal encargado de atender las citas/reservas es obligatoria.' }
    ]
  },
  loading: {
    spinner: 'bubbles',
    messages: {
      login: 'Iniciando sesión',
      createdUser: 'Creando usuario',
      editUser: 'Editando usuario',
      createdAppointment: 'Creando cita',
      updatedAppointment: 'Editando cita',
      createdProcedure: 'Creando procedimiento',
      updatedProcedure: 'Editando procedimiento',
      createBusinessHours: 'Guardando horarios de atención'
    }
  },
  mensajesInformativos: {
    cuentaCreada: {
      titulo: 'Registro exitoso!',
      mensaje: 'Enviamos un correo electrónico para confirmar tu cuenta, cuando la confirmes podrás iniciar sesión y disfrutar de los beneficios que Agéndate App tiene para ti y tu empresa!!!'
    },
    notificationEmailRecoverPassword: {
      titulo: 'Código de recuperación',
      mensaje: `Enviamos un código de recuperación a tu email para restablecer tu contraseña. <br><br>
      Diligencia el formulario de <strong>Restablecer mi contraseña</strong> y continua disfrutando de los servicios de Agéndate App.`
    },
    notificationChangePasswordSuccess: {
      titulo: '¡Contraseña cambiada!',
      mensaje: `Tu contraseña ha sido cambiada correctamente, ya puedes iniciar sesión y continuar 
      disfrutando de los servicios de Agéndate App.`
    },
    sinInternet: {
      titulo: 'Sin internet',
      mensaje: 'No se ha detectado ninguna conexión a internet, por favor verifique su conexión y vuelva a intentarlo.'
    },
    noUsersAttendAppointments: {
      titulo: '¡Atención!',
      mensaje: `Crea un nuevo usuario <strong>atiende citas</strong> o verifica el estado de tus usuarios.`
    },
    noUsersAttendAppointmentsNewAppointment: {
      titulo: '¡Atención!',
      mensaje: `Para crear una cita se necesita un usuario <strong>atiende citas</strong> verifica que tus usuarios tengan un tipo de procedimiento asignado y el estado sea activo o crea un nuevo usuario de tipo atiende citas y asígnale los tipos de procedimientos que atiende.`
    },
    fechaMenorAgendarCita: {
      titulo: 'Fecha y hora incorrecta',
      mensaje: 'La fecha y hora ingresada para la cita debe ser posterior a la actual.'
    },
    noHayCitasDisponibles: {
      titulo: 'No hay citas disponibles',
      mensaje: 'No hay citas disponibles para la fecha seleccionada.'
    },
    autoAgendamientoCitaCreada: {
      titulo: 'Gracias por confirar en nosotros',
      mensaje: 'Tu agendamiento ha sido diligenciado exitosamente.<br>Pronto recibirás un correo electrónico o mensaje de texto confirmando los detalles de tu cita.'
    },
    horarioAtencion:{
      titulo: 'Horario guardado',
      mensaje: 'El horario de atención ha sido guardado correctamente.'
    },
    pendientePago:{
      titulo: 'Plan pendiente de pago',
      mensaje: 'tu plan está pendiente de pago, comunícate con el área de ventas '
    }
  },
  skeletonList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  services: {
    types: {
      success: 'success',
      error: 'error'
    },
    msnCode: [
      {
        id: 0,
        title: 'Se produjo un error.',
        messageDescription: 'Lo sentimos, se están presentando fallas en el servicio por favor contactos para ayudarte a resolver el problema, toma una foto del error y contactanos gracias.'
      },
      {
        id: 1005,
        title: 'Advertencia.',
        messageDescription: 'El atendedor ya tiene asignada una cita para la fecha y hora seleccionada.'
      },
      {
        id: 5005,
        title: '',
        messageDescription: 'No se ha encontrado este usuario, no se encuentra registrado.'
      },
      {
        id: 5006,
        title: 'Usuario inactivo',
        messageDescription: 'El usuario no se encuentra activo, contáctanos para poder ayudarte.'
      },
      {
        id: 5010,
        title: 'Organización inactiva',
        messageDescription: 'La organización no se encuentra activa, contáctanos para poder ayudarte.'
      },
      {
        id: 5011,
        title: 'Límite alcanzado',
        messageDescription: 'Has creado todos los usuarios atiende citas disponibles en tu plan.<br><br>Adiciona más usuarios a tu plan para que sigan gestionando tus citas.<br><br>Contáctanos.'
      },
      {
        id: 5012,
        title: 'Verifica tu número de celular',
        messageDescription: 'Verifica el número celular principal de la cuenta para poder crear citas y notificarle a tus clientes.'
      },
      {
        id: 5013,
        title: 'Límite de notificaciones',
        messageDescription: 'Has usado todas las notificaciones de tu plan, se seguirán enviando pero tendrán un cobro adicional.<br><br>Contáctanos.'
      },
      {
        id: 5014,
        title: 'Límite de notificaciones',
        messageDescription: 'Has usado todas las notificaciones de tu plan, Adiciona más notificaciones a tu plan para que sigas comunicándote con tus clientes.<br><br>Contáctanos.'
      },
      {
        id: 5015,
        title: 'Plan vencido',
        messageDescription: 'Su plan se encuentra vencido, por favor ponte en contacto con el equipo de ventas para activar el plan.'
      }
    ]
  },
  terminosYcondiciones: {
    titulo: 'Términos y condiciones de uso.',
    descripcion: `<p class="parrafos ion-text-justify"> <small> Al registrarse en el sistema de Agéndate App (en adelante “Agéndate App” o la “EMPRESA” o el “CONTRATISTA”) damos cumplimiento a la Ley 1581 de 2.012 y su Decreto Reglamentario 1377 de 2013, le informamos que los datos personales de su propiedad, serán tratados, almacenados, usados y procesados en nuestra base de datos, cuya finalidad es gestionar el envío de notificaciones para su empresa o sus clientes y hacerle partícipe de nuestros eventos promocionales. Agéndate App garantiza el ejercicio de los derechos de acceso, rectificación, cancelación y oposición de los datos facilitados. Si desea consultar o eliminar sus datos, puedo hacerlo mediante comunicación escrita dirigida al correo electrónico administracion@agendateapp.com </small> </p>
    <p class="parrafos ion-text-justify"> <small> Las presentes Condiciones de uso establecen el acceso y utilización como Usuario (en adelante, “EL CONTRATANTE”) de nuestra Web y aplicación móvil. Si usted se registra en nuestra Web, App móvil o simplemente navega, modifica, confirma, cambia o cancela citas a través de nuestra plataforma, significa que acepta sin excepción las presentes condiciones de uso. Si no está de acuerdo con estas condiciones abstenerse de navegar y/o utilizar la web o app, ya que al hacerlo está aceptando estas condiciones de uso. </small> </p>
    <h6 class="titulos font-size-xs">Pagos</h6>
    <p class="parrafos ion-text-justify"> <small> El CONTRATANTE pagará un plan mensual, trimestral, semestral o anual según su elección a través de los medios de pago aprobados por el CONTRATISTA, como tarjeta de crédito, transferencia Tipo PSE, tarjeta débito, consignación bancaria o transferencia electrónica, Pago vía Baloto o efecty. El CONTRATANTE deberá enviar al CONTRATISTA el respectivo comprobante como prueba del pago para activar los servicios contratados. </small> </p>
    <p class="parrafos ion-text-justify"> <small> Si el plan contratado no es pagado, no se prestarán los servicios ofrecidos por el CONTRATISTA; por lo que el plan deberá estar pagado en su totalidad y confirmado a fin de obtener la prestación de los servicios, lo anterior deduce que lo que garantiza la prestación del servicio es el pago y confirmación del mismo por parte del CONTRATISTA. La falta de pago por parte del CONTRATANTE constituye un incumplimiento y libera al CONTRATISTA de cualquier responsabilidad. </small> </p>
    <h6 class="titulos font-size-xs">Cancelación del plan</h6>
    <p class="parrafos ion-text-justify"> <small> El CONTRATANTE podrá cancelar el plan contratado con un límite de 48 horas previas a que finalice la vigencia del plan, sin generar ningún costo para el CONTRATANTE, notificando al CONTRATISTA de la respectiva novedad. En el caso de no realizarse la cancelación del plan en el límite de 48 horas previas, las notificaciones consumidas serán cobradas en su totalidad sólo si el plan que tiene contratado esta sin restricción de créditos (La restricción de créditos permite que al consumirse el total de notificaciones del plan no se puedan crear, modificar o reenviar notificaciones de citas agendadas para sus clientes). </small> </p>
    <p class="parrafos ion-text-justify"> <small> El CONTRATISTA generará la respectiva factura por los servicios contratados en el plan, a su vez el CONTRATANTE acepta que esta factura le sea enviada por correo electrónico o que esté disponible para su visualización o descarga en su cuenta de usuario en la web o app móvil. </small> </p>
    <h6 class="titulos font-size-xs">Tratamiento de datos personales</h6>
    <p class="parrafos ion-text-justify"> <small> El CONTRATANTE da su consentimiento al CONTRATISTA para que use toda la información suministrada para la consecución del servicio y para los fines comerciales del CONTRATISTA, la cual será tratada de acuerdo a la legislación de tratamiento de datos de nuestro país. </small> </p>
    <p class="parrafos ion-text-justify"> <small> En cada cita creada, reenviada o cancelada el cliente del CONTRATANTE recibirá la información y datos del profesional que va a atender la cita y datos de la empresa donde se debe dirigir para dar cumplimiento a la cita asignada, el CONTRATANTE se comprometen a usar esta información y documentación única y exclusivamente para los fines del servicio contratado, y estos no pueden ser usados ni compartidos para ningún otro fin. Así mismo el CONTRATISTA se compromete a cumplir con la POLÍTICA DE TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES de AGÉNDATE APP. </small> </p>
    <h6 class="titulos font-size-xs">Uso indebido de Agéndate App en la web o app móvil.</h6>
    <p class="parrafos ion-text-justify"> <small> Cuando el CONTRATANTE incumpla alguna de las condiciones de uso podrá perder el pago realizado, sin impedir que el CONTRATISTA persiga el incumplimiento de las condiciones de uso así como cualquier uso indebido de la web www.agendateapp.com o del app, ejerciendo todas las acciones jurídicas a las que tenga derecho de acuerdo a la legislación. En todo caso el CONTRATANTE se obliga a suministrar información verídica y actualizada, evitando el suministro de información incompleta o falta en el uso de la web o app. </small> </p>
    <h6 class="titulos font-size-xs">Cancelación de cuenta.</h6>
    <p class="parrafos ion-text-justify"> <small> El CONTRATISTA se reserva el derecho de cancelar cualquier cuenta en caso de que la misma sea utilizada en contravención a las presentes condiciones de uso y del servicio. También podrá cancelar la cuenta de un usuario, suspender, alterar o eliminar toda o parte de la información contenida en su cuenta con o sin notificación; también podrá poner en conocimiento de las Autoridades competentes cualquier comportamiento realizado por un usuario sospechoso de ser objeto de alguna reclamación, o de incumplir alguno de los apartados de las presentes Condiciones de uso; finalmente acatará cualquier requerimiento de una Autoridad competente en relación con información contenida en su cuenta. también podrá cancelar cuentas falsas creadas con datos falsos. </small> </p>
    <h6 class="titulos font-size-xs">Marco legal aplicable.</h6>
    <p class="parrafos ion-text-justify"> <small> Para todos los efectos contractuales, legales y fiscales atinentes a estas condiciones de uso, las partes acuerdan como domicilio contractual a la ciudad de Santiago de Cali. Las diferencias que surjan acerca de la interpretación de este contrato y su ejecución, incumplimiento o consecuencias futuras, si no pudiesen arreglar de común acuerdo, se someterán a la decisión en un centro de conciliación legalmente establecido en la Ciudad de Santiago de Cali, Colombia, sin ser necesario tribunal de arbitramento. Si no se llegare a un arreglo, no se llegare a presentar la contraparte o se incumpliere el acuerdo, se dará inicio al proceso para el cumplimiento del contrato.<br><br> Términos y condiciones de uso Versión 1.0.1 Domingo, 7 de junio del 2020. </small> </p>
    <p class="parrafos ion-text-left"> <small> Cordialmente,<br> Equipo de Agéndate App<br> <strong>Email:</strong> <a href="mailto:administracion@agendateapp.com">administracion@agendateapp.com</a><br> <strong>Página web:</strong> <a href="https://agendateapp.com" target="_blanck">https://agendateapp.com</a><br> </small> </p>`
  }
};
