import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// Redux
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducers';
import * as personalizationClientActions from './store/actions/personalizationClient.actions';
// import { FCM } from '@ionic-native/fcm/ngx';
import { Constants } from '../providers/constants/constants';
import { ToolsService } from './service/tools/tools.service';
// import { FirebaseanalyticsService } from './service/firebaseanalytics/firebaseanalytics.service';
import { PushService } from './service/push/push.service';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  dataPersonalizationClientSubs: Subscription;
  // public selectedIndex = 0;
  // public appPages = [
  //   {
  //     title: 'Inbox',
  //     url: '/folder/Inbox',
  //     icon: 'mail'
  //   },
  //   {
  //     title: 'Outbox',
  //     url: '/folder/Outbox',
  //     icon: 'paper-plane'
  //   },
  //   {
  //     title: 'Favorites',
  //     url: '/folder/Favorites',
  //     icon: 'heart'
  //   },
  //   {
  //     title: 'Archived',
  //     url: '/folder/Archived',
  //     icon: 'archive'
  //   },
  //   {
  //     title: 'Trash',
  //     url: '/folder/Trash',
  //     icon: 'trash'
  //   },
  //   {
  //     title: 'Spam',
  //     url: '/folder/Spam',
  //     icon: 'warning'
  //   }
  // ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constants = Constants;
  dataUser: any = {};
  constructor(
    private store: Store<AppState>,
    private elementRef: ElementRef,
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navController: NavController,
    private toolsService: ToolsService,
    // private firebaseanalyticsService: FirebaseanalyticsService,
    private pushService: PushService
    // private fcm: FCM
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      CapacitorApp.addListener('backButton', ({ canGoBack }) => { if (!canGoBack) { CapacitorApp.exitApp(); } else { window.history.back(); } });
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getPersonalizacion();
      this.validateActiveSession();
      // this.firebaseanalyticsService.setEnabledFirebaseAnalytics(true);
      this.pushService.getToken().then(); // .then(res => {});
      this.escucharNotificacionesPush();
    });
  }

  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user));
    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
    this.router.events.pipe(
        filter( event => event instanceof ActivationEnd ),
        filter( (event: ActivationEnd) => event.snapshot.firstChild === null  ),
        map( (event: ActivationEnd) => event.snapshot.data ),
      ).subscribe((data)=> {
        // try {
        //   this.firebaseanalyticsService.logEvent('page_view', {title:data.titlePage, url: this.router.url});
        //   this.firebaseanalyticsService.screenView(data.titlePage);
        // } catch (error) {}
      });
  }

  /**
   * @description obtiene la personalización del store y setea el color primario en el app
   */
  getPersonalizacion(){
    this.dataPersonalizationClientSubs = this.store.select('personalizationClient').subscribe(({data}) => {
      if(data !== null){
        if (data?.color_principal !== '') {
          this.elementRef.nativeElement.style.setProperty('--ion-color-primary', `#${data?.color_principal}`);
        }
      }
    });
  }

  validateActiveSession() {
    // si existe un token de sesion pasa al home
    if (localStorage.getItem(this.constants.localStorage.token) && localStorage.getItem(this.constants.localStorage.token) !== 'undefined') {
      this.store.dispatch(personalizationClientActions.triggerPersonalizationClientInit({subDominio: this.dataUser.organizaciones.sub_dominio}));
      this.navController.navigateRoot('/home');
    } else if (localStorage.getItem(this.constants.localStorage.initAppSlider) === 'true') {
      this.toolsService.clearDataRedirectLogin();
      this.navController.navigateRoot('/login');
    }
  }

  escucharNotificacionesPush() {
    this.pushService.onMessageReceived().subscribe({
      next: (notificacion: any) => {
      console.log('-----------------notificacion-----------------');
      console.log(notificacion);
      console.log(notificacion?.messageType);
      // if(notificacion?.messageType === 'notification'){
      if(notificacion?.tap !== 'background'){
        const dataNotificacionPus = {
          titulo: notificacion.title,
          mensaje: notificacion.body
        };
        // esta nottificacion sera temporal
        this.toolsService.alertNotificacionSimple(dataNotificacionPus);
        // this.pushService.addNotificationToList(notificacion);
      }
    }, error: (e) => {
      // console.log('-----------------err escucharNotificacionesPush-----------------');
      // console.log(e);
    }});
  }
}
