import { createReducer, on } from '@ngrx/store';
import { dataSeoPaginaSuccess } from '../actions';

export interface dataSeoPageState {
  data: any;
  loading: boolean;
  error: any;
}

export const dataSeoPageInitialState: dataSeoPageState = {
  data: null,
  loading: false,
  error: null
};

const _dataSeoPageReducers = createReducer(dataSeoPageInitialState,
  // on(consumptionReportApiInit, state => ({
  //   ...state,
  //   loading: true,
  // })),

  on(dataSeoPaginaSuccess, (state, action) => ({
    ...state,
    data: {...action.dataSeoPage},
    loading: false,
    error: {}
  }))
);

export function dataSeoPageReducer(state, action) {
  return _dataSeoPageReducers(state, action);
}
