import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
  personalizationClient: reducers.PersonalizationClientState,
  consumptionReportApi: reducers.consumptionReportApiState,
  dataSeoPage: reducers.dataSeoPageState
}

export const appReducers: ActionReducerMap<AppState> = {
  personalizationClient: reducers.personalizationClientReducer,
  consumptionReportApi: reducers.consumptionReportApiReducer,
  dataSeoPage: reducers.dataSeoPageReducer
};
