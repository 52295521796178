import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform( arreglo: any[], texto: string, columna: string ): any[] {
    return arreglo.filter( item => {
      return item[columna]._id === texto;
    });
  }

}
