import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: '',
    redirectTo: 'sliderhome',
    pathMatch: 'full'
  },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), data: { titlePage: 'Gestión de citas'} },
  { path: 'buscarusuarionuevacita', loadChildren: () => import('./pages/buscarusuarionuevacita/buscarusuarionuevacita.module').then(m => m.BuscarusuarionuevacitaPageModule), data: { titlePage: 'Buscador de usuarios nueva cita'} },
  { path: 'formnuevacita', loadChildren: () => import('./pages/formnuevacita/formnuevacita.module').then(m => m.FormnuevacitaPageModule), data: { titlePage: 'Crear citas'} },
  { path: 'listcitasporconfirmar', loadChildren: () => import('./pages/listcitasporconfirmar/listcitasporconfirmar.module').then(m => m.ListcitasporconfirmarPageModule), data: { titlePage: 'Listado de citas por confirmar'} },
  { path: 'listcitasconfirmadas', loadChildren: () => import('./pages/listcitasconfirmadas/listcitasconfirmadas.module').then(m => m.ListcitasconfirmadasPageModule), data: { titlePage: 'Listado de citas confirmadas'} },
  { path: 'listcitascanceladas', loadChildren: () => import('./pages/listcitascanceladas/listcitascanceladas.module').then(m => m.ListcitascanceladasPageModule), data: { titlePage: 'Listado de citas canceladas'} },
  { path: 'listcitasatendidas', loadChildren: () => import('./pages/listcitasatendidas/listcitasatendidas.module').then( m => m.ListcitasatendidasPageModule), data: { titlePage: 'Listado de citas atendidas'} },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), data: { titlePage: 'Iniciar sesión'} },
  { path: 'registeraccount', loadChildren: () => import('./pages/registeraccount/registeraccount.module').then(m => m.RegisteraccountPageModule), data: { titlePage: 'Registrar nueva cuenta'} },
  { path: 'adminusers', loadChildren: () => import('./pages/adminusers/adminusers.module').then(m => m.AdminusersPageModule), data: { titlePage: 'Administrador de usuarios'} },
  { path: 'createandeditusers', loadChildren: () => import('./pages/createandeditusers/createandeditusers.module').then(m => m.CreateandeditusersPageModule), data: { titlePage: 'Crear usuarios'} },
  { path: 'userfilter', loadChildren: () => import('./pages/modals/userfilter/userfilter.module').then(m => m.UserfilterPageModule) },
  { path: 'recoverpassword', loadChildren: () => import('./pages/recoverpassword/recoverpassword.module').then(m => m.RecoverpasswordPageModule), data: { titlePage: 'Recordar contraseña'} },
  { path: 'cancelappointment', loadChildren: () => import('./pages/modals/cancelappointment/cancelappointment.module').then(m => m.CancelappointmentPageModule), data: { titlePage: 'Cancelar cita'} },
  { path: 'sliderhome', loadChildren: () => import('./pages/sliderhome/sliderhome.module').then(m => m.SliderhomePageModule), data: { titlePage: 'Slider bienvenida'} },
  { path: 'searchtypesofprocedures', loadChildren: () => import('./pages/searchtypesofprocedures/searchtypesofprocedures/searchtypesofprocedures.module').then(m => m.SearchtypesofproceduresPageModule), data: { titlePage: 'Adminsitrador tipos de procedimientos'} },
  { path: 'createandeditprocedures', loadChildren: () => import('./pages/createandeditprocedures/createandeditprocedures/createandeditprocedures.module').then(m => m.CreateandeditproceduresPageModule), data: { titlePage: 'Crear procedimientos'} },
  { path: 'plandetail', loadChildren: () => import('./pages/plandetail/plandetail.module').then( m => m.PlandetailPageModule), data: { titlePage: 'Detalle de plan'} },
  { path: 'horariodeatencion', loadChildren: () => import('./pages/businesshours/businesshours.module').then( m => m.BusinesshoursPageModule), data: { titlePage: 'Administrador de Horarios de atención'} },
  { path: 'estadisticas', loadChildren: () => import('./pages/estadisticas/estadisticas.module').then( m => m.EstadisticasPageModule), data: { titlePage: 'Estadisticas'} },
  { path: 'organizacion', loadChildren: () => import('./pages/organizacion/organizacion.module').then( m => m.OrganizacionPageModule), data: { titlePage: 'Datos organizacion'} },
  { path: 'configuraciones', loadChildren: () => import('./pages/configuraciones/configuraciones.module').then( m => m.ConfiguracionesPageModule), data: { titlePage: 'Configuraciones'} },
  { path: 'terminos-y-condiciones', loadChildren: () => import('./pages/terminosycondiciones/terminosycondiciones.module').then( m => m.TerminosycondicionesPageModule), data: { titlePage: 'Terminos y condiciones'} },
  { path: 'politica-tratamiento-de-datos', loadChildren: () => import('./pages/politica-tratamiento-de-datos/politica-tratamiento-de-datos.module').then( m => m.PoliticaTratamientoDeDatosPageModule), data: { titlePage: 'Política de tratamiento de datos personales'} },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
