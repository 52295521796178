import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(public Api: ApiService) { }

  login(credentials) {
    return this.Api.post('/auth/login', credentials);
  }

  logout(credentials) {
    return this.Api.post('/auth/logout', credentials);
  }

  renewToken() {
    return this.Api.get('/auth/renew');
  }

  recoverPassword(email) {
    return this.Api.post('/auth/recover', email);
  }

  changePassword(data) {
    return this.Api.post('/auth/change/password', data);
  }

}
