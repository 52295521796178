import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(public Api: ApiService) { }

  getUsers() {
    return this.Api.get('/user/');
  }

  getUsersSearch(data: any) {
    // data = encodeURI(data);
    // return this.Api.get(`/account/user/read/${data}`);
    return this.Api.post('/dashboard/search', data);
  }

  getUsersSearchAppointment(data: any) {
    return this.Api.post('/dashboard/searchappointment', data);
  }

  postCreateUser(data: any) {
    return this.Api.post('/account/user/create', data);
  }

  putUpdateUser(iduser, data: any) {
    return this.Api.put(`/account/user/update/${iduser}`, data);
  }

  deleteUser(iduser) {
    return this.Api.delete(`/account/user/delete/${iduser}`);
  }

  /**
   * @description permite enviar código de verificacion de numero de telefo y tambien permite validar el código 
   * @param data data con el numero de telefeno a verificar
   */
  postUserCellVerification(data: any) {
    return this.Api.post('/user/check', data);
  }
}
