import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class AppointmentService {

  constructor(public Api: ApiService) { }

  getForwardAppointment(data: any) {
    return this.Api.post(`/notification/resend/${data}`, {});
  }

  postCreateAppointment(data: any) {
    return this.Api.post('/appointment_management/create', data);
  }

  putUpdateAppointment(idAppointment, data: any) {
    return this.Api.put(`/appointment_management/update/${idAppointment}`, data);
  }

  putCancelAppointment(idAppointment, data: any) {
    return this.Api.put(`/appointment_management/cancel/${idAppointment}`, data);
  }

  postValidarAgendaAtendedorPorProcedimientoDia(data: any) {
    return this.Api.post('/client/appointment/validate', data);
  }

  putActualizarEstadoCita(idAppointment, data: any) {
    return this.Api.put(`/appointment/simple/${idAppointment}`, data);
  }

  postGlobalsearchProcedimeintos(data: any) {
    return this.Api.post(`/appointment/globalsearch/`, data);
  }
}
