import { createAction, props } from '@ngrx/store';
// import { IPersonalizationClient } from '../../interfaces/IPersonalizationClient';

export const consumptionReportApiInit = createAction(
  '[Informe de consumo API] => consumptionReportApiInit'
);

export const consumptionReportApiSuccess = createAction(
  '[Informe de consumo API] => consumptionReportApiSuccess',
  props<{ consumptionReportApi: any }>()
);


