import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import * as personalizationClientActions from '../actions';
import { tap, mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { ClientService } from '../../service/client/client.service';
import { of, Observable } from 'rxjs';
import { Constants } from '../../../providers/constants/constants';

@Injectable()
export class personalizationClientEffects {
  constants = Constants;
  constructor(
    private actions$: Actions,
    private clientService: ClientService
  ) { }

  // cargarPersonalizacionCliente$ = createEffect(() => this.actions$.pipe(ofType(personalizationClientActions.triggerPersonalizationClientInit),
  //   // permite ver la data que se esta procesando
  //   // tap(data=> console.log('effect tap ', data)),
  //   mergeMap(
  //     (action) => this.clientService.postClientOrganizationValidate({ sub_dominio: action.subDominio })
  //       .pipe(
  //         tap(data => console.log('personalizacion effect', data)),
  //         map((typeResponse: any) => (
  //           // if (typeResponse === this.constants.services.types.success) {
  //           personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: personalizacion }))
  //           // }
  //         )
  //         // catchError(err => of(usuariosActions.cargarUsuarioError({ payload: err })))
  //       )
  //   )
  // ));

  cargarPersonalizacionCliente$ = createEffect(() => this.actions$.pipe(ofType(personalizationClientActions.triggerPersonalizationClientInit),
    // permite ver la data que se esta procesando
    // tap(data=> console.log('effect tap ', data)),
    mergeMap((action: any) => { 
        return this.clientService.postClientOrganizationValidate({ sub_dominio: action.subDominio })
        .pipe(
          tap(data => console.log('personalizacion effect', data)),
          map((typeResponse: any) => (
            typeResponse.type === this.constants.services.types.success ? personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: typeResponse.data.personalization }) : personalizationClientActions.triggerPersonalizationClientSuccess({ personalizationCliente: null }))
          )
          // catchError(err => of(usuariosActions.cargarUsuarioError({ payload: err })))
        )}
    )
  ));
}
