import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Share } from '@capacitor/share';
import { Platform } from '@ionic/angular';

import { Constants } from '../../../providers/constants/constants';
import { AuthService } from '../../service/auth/auth.service';
import { ToolsService } from '../../service/tools/tools.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  constants = Constants;
  dataUser: any = {};
  nameUSer = '';
  nameRoleUser = '';
  versioApp = this.constants.versioApp;
  currentUrl = '';
  additionalPages: any = [
  {
    title: 'Autoagendamiento de citas',
    url: '/searchtypesofprocedures',
    icon: 'share-social-outline',
    data: {
      logout: false,
      dataEvento: {
        texto: 'Agenda tu cita aquí',
        url: 'https://agendateapp.com/citas/'
      }
    }
  },
  {
    title: 'Lista de precios',
    url: '/searchtypesofprocedures',
    icon: 'share-social-outline',
    data: {
      logout: false,
      dataEvento: {
        texto: 'Conoce nuestros servicios aquí',
        url: 'https://agendateapp.com/servicios/'
      }
    }
  },
  {
    title: 'Configuración',
    url: '/configuraciones',
    icon: 'settings-outline',
    data: {
      logout: false
    } 
  },
  {
    title: 'Cerrar sesión',
    url: '/login',
    icon: 'log-in-outline',
    data: {
      logout: true
    }
  }];
  public appPages = [];

  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    public navController: NavController,
    public authService: AuthService,
    public toolsService: ToolsService,
    private platform: Platform,
  ) { }
  
  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user));
    // validacion por ahora para no mostrar enlace de detalle de plan
    if (Capacitor.getPlatform() === 'ios') {
      this.additionalPages = [
      {
        title: 'Cerrar sesión',
        url: '/login',
        icon: 'log-in-outline',
        data: {
          logout: true
        }
      }];
    }
    this.appPages = [
      {
        title: 'Citas',
        url: '/home',
        icon: 'calendar-outline',
        data: {
          logout: false
        }
      },
      {
        title: 'Administrar usuarios',
        url: '/adminusers',
        icon: 'people-outline',
        data: {
          logout: false
        }
      },
      {
        title: 'Estadisticas',
        url: '/estadisticas',
        icon: 'stats-chart-outline',
        data: {
          logout: false
        }
      },
      ...this.additionalPages
    ];
    this.inactivarMenuNavegacionLateral();
  }

  /**
   * @description valida los eventos del router e inactiva el menu de navegacion cuando esta en la vista de login , undefined o / 
   * si se esta en una vista distinta a login se hace el llamado a las opciones del menu de navegacion para mantener actualizado el menu
   */
  
  inactivarMenuNavegacionLateral() {
    this.router.events.subscribe((event: RouterEvent) => {
      this.currentUrl = event.url;
      if (event instanceof NavigationEnd && (this.toolsService.validateUrlInactivateMenu(this.currentUrl))) {
        this.menuCtrl.enable(false);
      } else if (event instanceof NavigationEnd) {
        this.getDataUser();
        this.menuCtrl.enable(true);
      }
    });
  }

  getDataUser() {
    if (localStorage.getItem(this.constants.localStorage.token)) {
      this.nameUSer = `${JSON.parse(localStorage.getItem(this.constants.localStorage.user)).nombres} ${JSON.parse(localStorage.getItem(this.constants.localStorage.user)).apellidos}`;
      this.nameRoleUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user)).roles.nombre;
    }
  }

  openPage(page) {
    if (page.data.logout) {
      this.logout();
    } else {
      this.validateTokenRenewal();
      if(page.data?.dataEvento){
        this.compartirEnlace(page.data?.dataEvento);
        return;
      }
      this.router.navigate([page.url]);
    }
  }

  irAlPerfil(){
    this.menuCtrl.close();
    this.router.navigate(['organizacion']);
  }

  /**
   * Comparte url con la funcion de compartir del sispositivo movil, si esta en web valida que sea compatible si no 
   * copia la url en el portapeles del usuario.
   * @param data 
   */
  async compartirEnlace(data: any) {
    const url = `${data?.url}${this.dataUser?.organizaciones.sub_dominio}`;
    const dataShare = {
      title: '',
      text: data?.texto,
      url: url,
      dialogTitle: 'Agéndate App',
    };
    if (this.platform.is('android') || this.platform.is('ios')) {
      await Share.share(dataShare);
    } else if (navigator.share && navigator.share != undefined) {
        navigator.share(dataShare)
        .then(() => console.log(''))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(url)
      .then(() => console.log(''))
      .catch((error) => console.log('Error clipboard writeText', error));
      this.toolsService.presentToast('Enlace copiado.');
    }
  }

  validateTokenRenewal() {
    this.authService.renewToken()
      .subscribe({
        next: (response) => {
          console.log(response);
          console.log(response['token']);
          if (response['renew']) {
            this.toolsService.updateUserToken(response['token']);
          }
        }, error: (err) => {
          this.toolsService.statusService(err);
        }
      });
  }

  logout() {
    const data = { token: localStorage.getItem(this.constants.localStorage.token) };
    this.authService.logout(data)
      .subscribe({
        next: (response) => {
          // if (response['type'] === this.constants.services.types.success) {
          setTimeout(() => {
            this.toolsService.clearDataRedirectLogin();
          }, 500);
          // }
        }, error: (err) => {
          this.toolsService.statusService(err);
        }
      });
  }
}
