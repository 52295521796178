import { createReducer, on } from '@ngrx/store';
import { consumptionReportApiInit, consumptionReportApiSuccess } from '../actions';
// import { IPersonalizationClient } from '../../interfaces/IPersonalizationClient';

export interface consumptionReportApiState {
  data: any;
  loading: boolean;
  error: any;
}

export const consumptionReportApiInitialState: consumptionReportApiState = {
  data: null,
  loading: false,
  error: null
};

const _consumptionReportApiReducers = createReducer(consumptionReportApiInitialState,
  on(consumptionReportApiInit, state => ({
    ...state,
    loading: true,
  })),

  on(consumptionReportApiSuccess, (state, action) => ({
    ...state,
    data: {...action.consumptionReportApi},
    loading: false,
    error: {}
  }))
);

export function consumptionReportApiReducer(state, action) {
  return _consumptionReportApiReducers(state, action);
}
