import { createReducer, on } from '@ngrx/store';
import { triggerPersonalizationClientSuccess, triggerPersonalizationClientInit } from '../actions';
import { IPersonalizationClient } from '../../interfaces/IPersonalizationClient';
// import { UsuarioI } from '../../interfaces/usuario';

export interface PersonalizationClientState {
  data: IPersonalizationClient;
  loading: boolean;
  error: any;
}

export const personalizationClientInitialState: PersonalizationClientState = {
  data: null,
  loading: false,
  error: null
};

const _personalizationClientReducers = createReducer(personalizationClientInitialState,
  on(triggerPersonalizationClientInit, state => ({
    ...state,
    loading: true,
  })),

  on(triggerPersonalizationClientSuccess, (state, action) => ({
    ...state,
    data: {...action.personalizationCliente},
    loading: false,
    error: {}
  }))
);

export function personalizationClientReducer(state, action) {
  return _personalizationClientReducers(state, action);
}
