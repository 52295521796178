import { Component, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

import { Constants } from '../../../providers/constants/constants';
import { ToolsService } from './../../service/tools/tools.service';
import { UserService } from './../../service/users/user.service';

@Component({
  selector: 'app-verifyphonenumber',
  templateUrl: './verifyphonenumber.component.html',
  styleUrls: ['./verifyphonenumber.component.scss'],
})
export class VerifyphonenumberComponent implements OnInit {
  constants = Constants;
  showCard = false;
  dataUser: any = {};
  idUserSesion: string = '';
  rolIdUSer: string = '';
  nameUser: string = '';
  phoneNumberUser: string = '';
  phoneVerification: boolean = true;

  constructor(
    public alertController: AlertController,
    public toastController: ToastController,
    public toolsService: ToolsService,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.dataUser = JSON.parse(localStorage.getItem(this.constants.localStorage.user));
    this.idUserSesion = this.dataUser._id;
    this.rolIdUSer = this.dataUser.roles._id;
    this.nameUser = this.dataUser.nombres;
    this.phoneNumberUser = this.dataUser.celulares[0];
    this.phoneVerification = this.dataUser.celular_verificado;

    if (this.phoneVerification === false && this.rolIdUSer === this.constants.roles.administrador) {
      this.showCard = true;
    }
  }

  hiddenCard() {
    this.showCard = false;
  }

  async presentAlertEditPhoneNumberUser() {
    const input = await this.alertController.create({
      header: 'Editar número de teléfono',
      // subHeader: 'Ingrese su nombre:',
      message: `<p class="ion-text-left">Hola <strong>${this.nameUser}</strong>, ingresa tu número de teléfono celular para editarlo y proceder a enviar el código de verificación.</p>`,
      mode: 'ios',
      translucent: true,
      animated: true,
      backdropDismiss: false,
      inputs: [
        {
          name: 'phoneNumber',
          type: 'number',
          placeholder: 'Ingresa tu número de teléfono celular'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Editar',
          handler: (data) => {
            const phoneNumber = data.phoneNumber;

            if (!this.toolsService.validateNumber(phoneNumber)) {
              this.toolsService.presentToast(this.constants.validationMessages.isNAn[0].message);
              return false;
            }

            if (phoneNumber.length === 10 && !isNaN(phoneNumber)) {
              this.editPhoneNumberUser(phoneNumber);
            } else {
              this.toolsService.presentToast(this.constants.validationMessages.celulares[1].message);
              return false;
            }
          }
        }
      ]
    });
    await input.present();
  }

  editPhoneNumberUser(phoneNumber) {
    const dataUSer = {
      'celulares': [phoneNumber.trim()],
    };
    this.userService.putUpdateUser(this.idUserSesion, dataUSer)
      .subscribe({ next: (response) => {
        const typeResponse = response['type'];
        if (typeResponse === this.constants.services.types.success) {
          this.phoneNumberUser = phoneNumber;

          this.dataUser.celulares[0] = phoneNumber.toString();
          localStorage.setItem(this.constants.localStorage.user, JSON.stringify(this.dataUser));

          this.toolsService.presentToast(this.constants.users.notificationUpdate, 2000);
          this.sendVerificationCodePhoneNumber();
        } else {
          this.toolsService.presentToast(response['message'].messageDescription);
        }
      }, error: (err) => {
        this.toolsService.statusService(err);
      }
      });
  }

  sendVerificationCodePhoneNumber() {
    const data = {
      'id': this.idUserSesion,
      'celular': this.phoneNumberUser,
      'type': 'send'
    };
    this.userService.postUserCellVerification(data).subscribe({ next: (response) => {
      const typeResponse = response['type'];
      if (typeResponse === this.constants.services.types.success) {
        this.presentAlertVerifyPhoneNumber();
      } else if (typeResponse === this.constants.services.types.error) {
        this.toolsService.presentToast(response['message'].messageDescription);
      }
    }, error: (err) => {
    }
    });
  }

  async presentAlertVerifyPhoneNumber() {
    const input = await this.alertController.create({
      header: 'Vamos a verificar tu número de teléfono',
      // subHeader: 'Ingrese su nombre:',
      message: `<p class="ion-text-left">${this.nameUser}, Agéndate envío un mensaje SMS al <strong>${this.phoneNumberUser}</strong> con un código para verificar tu número de teléfono.</p>`,
      mode: 'ios',
      translucent: true,
      animated: true,
      backdropDismiss: false,
      inputs: [
        {
          name: 'codeVerification',
          type: 'text',
          placeholder: 'Código de verificación'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Verificar',
          handler: (data) => {
            const codeVerification = data.codeVerification;
            if (codeVerification.length === 6) {
              this.verificationCodePhoneNumber(codeVerification);
            } else {
              this.toolsService.presentToast(this.constants.verificationCodePhoneNumber.minAndMaxCode);
              return false;
            }
          }
        }
      ]
    });
    await input.present();
  }

  verificationCodePhoneNumber(codeVerification: string) {
    const data = {
      'id': this.idUserSesion,
      'codigo': codeVerification,
      'type': 'verify'
    };
    this.userService.postUserCellVerification(data).subscribe({ next: (response) => {
      const typeResponse = response['type'];
      if (typeResponse === this.constants.services.types.success) {
        this.hiddenCard();
        this.toolsService.presentToast('Número de teléfono verificado.');
        // Actuaizo en local stire que se actualizo el usuario
        this.dataUser.celular_verificado = false;
        localStorage.setItem(this.constants.localStorage.user, JSON.stringify(this.dataUser));
      } else if (typeResponse === this.constants.services.types.error) {
        this.presentAlertVerifyPhoneNumber();
        this.toolsService.presentToast(response['message'].messageDescription);
      }
    }, error: (err) => {
    }
    });
  }
}
