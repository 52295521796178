import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { VerifyphonenumberComponent } from './verifyphonenumber/verifyphonenumber.component';

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MenuComponent,
    VerifyphonenumberComponent
  ],
  exports: [
    MenuComponent,
    VerifyphonenumberComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    // FormsModule,
    // ReactiveFormsModule,
  ]
})
export class ComponentsModule { }
