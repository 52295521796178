import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { ToastController, AlertController, LoadingController, NavController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
// import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import * as moment from 'moment';

import { Constants } from '../../../providers/constants/constants';
import { AppointmentService } from './../appointment/appointment.service';
@Injectable({ providedIn: 'root' })
export class ToolsService {
  constants = Constants;
  moment = moment;
  constructor(
    private router: Router,
    public navController: NavController,
    public toastController: ToastController,
    public alertController: AlertController,
    private callNumber: CallNumber,
    public loadingController: LoadingController,
    public appointmentService: AppointmentService,
    private currencyPipe: CurrencyPipe
    // private inAppBrowser: InAppBrowser
  ) { }

  statusService(data) {
    if (data.status === 401) {
      this.clearDataRedirectLogin();
    } else {
      // si se presenta un error diferente no detectado y se reporta al usuario para que nos envie notificacion de error
      this.alertErrorInformationWs({ msnCode: 0 }, true, this.constants.contactAgendate.supportTeam.nameKeyTeam);
    }
  }

  clearDataRedirectLogin() {
    localStorage.clear();
    localStorage.setItem(this.constants.localStorage.initAppSlider, 'true');
    this.router.navigate(['/login']);
  }

  getDataContactTeamAgendate(typeTeam: string) {
    const dataTeam = this.constants.contactAgendate[typeTeam];
    // <ion-icon name="mail" class="text-color-medium"></ion-icon> <a class="enlaces-no-decoration" href="mailto:${dataTeam.email}">${dataTeam.email}</a><br><br>
    // <ion-icon name="call" class="text-color-medium"></ion-icon> <a class="enlaces-no-decoration" href="tel:${dataTeam.cellPhoneNumber}">${dataTeam.cellPhoneNumber}</a><br><br>
    const message = `<ion-icon name="logo-whatsapp" class="text-color-medium"></ion-icon> <a class="enlaces-no-decoration" target="_blank" href="https://wa.me/57${dataTeam.whatsapp}">${dataTeam.whatsapp}</a>`;
    return message;
  }
  // para ejecutar pruebas de alertas
  // const dataError = {
  //   msnCode: 5011
  // };
  // this.toolsService.alertErrorInformationWs(dataError, true, 'salesTeam');
  async alertErrorInformationWs(dataError: any, isShowContact: boolean = false, typeTeam: string) {
    const dataMessageError = this.constants.services.msnCode.filter((idCode) => {
      return idCode.id === dataError.msnCode;
    });
    if (dataMessageError.length > 0) {
      const dataContact = isShowContact ? this.getDataContactTeamAgendate(typeTeam) : '';
      const headerTitle = dataMessageError[0].title;
      const informationalMessage = `${dataMessageError[0].messageDescription} <br><br> ${dataContact}`;
      const alert = await this.alertController.create({
        header: headerTitle,
        message: `<p class="ion-text-left">${informationalMessage}</p>`,
        translucent: true,
        animated: true,
        mode: 'ios',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Aceptar',
            handler: () => {
              this.validateActionBtnAlertHandler(dataError);
            }
          }
        ]
      });
      await alert.present();
    } else {
      await this.presentToast(dataError.message.messageDescription);
    }
  }

  /**
   * @description valida los codigos de error dependiendo el codigo se navega a otra vista o se cierra el modal
   * @param dataError data del error que se ppresento en el web services
   */
  validateActionBtnAlertHandler(dataError: any) {
    switch (dataError.msnCode) {
      case 5012:
        this.router.navigate(['/home']);
        break;
      case 5013:
        this.router.navigate(['/home']);
        break;
    }
  }

  async alertAppointmentDetail(data: any) {
    const horaFin = (data.citas.fecha_fin) ? ` a ${moment(data.citas.fecha_fin).format('h:mm A')}` : '';
    const valorDelProcedimiento = data.citas.procedimientos?.caracteristicas ? this.currencyPipe.transform(data.citas.procedimientos?.caracteristicas.valor_servicio.valor, 'COP', '$', '1.0-0', 'es-ES') : '';
    const valorDelProcedimientoHtml = valorDelProcedimiento ? `<strong class="text-primary">Valor: </strong> ${valorDelProcedimiento}<br>` : '';
    const alert = await this.alertController.create({
      // *ngIf="valorDelProcedimiento"
      header: 'Detalle de la cita',
      message: `<p class="ion-text-left">
                  <strong>Cliente:</strong> ${data.usuario.nombres} ${data.usuario.apellidos}<br>
                  <strong>Fecha:</strong> <span class="text-capitalize">${ this.parseDates(data.citas.fecha, 1)} ${horaFin}</span><br>
                  ${valorDelProcedimientoHtml}
                  <strong>Tipo de cita:</strong> ${data.citas.procedimientos.nombre}<br>
                  <strong>Atendido por:</strong> ${data.citas.usuario_atendedor.nombres} ${data.citas.usuario_atendedor.apellidos}<br>
                  <strong>Cita creada por:</strong> ${data.citas.usuario_agendador.nombres} ${data.citas.usuario_agendador.apellidos}<br>
                  <strong>Nota:</strong> ${data.citas.nota}
                </p>`,
      translucent: true,
      animated: true,
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * @description Funcion para sacar un alert de notificacion simple recibe un titulo y mensaje con una opcion de apceptar
   * @param data se debe pasar en formato json el ejemplo es {titulo: '', mensaje: ''}
   * @param redirectUrl url a la que se va a redirigir al presionar el botón de aceptar
   * @param dataRedirectUrl data que se envia a vistas de la app, en donde se reciben al terminar un proceso el usuario es redirigido
   * a la url que se envie en este parametro, ejemplo en la vista de buscar usuario nueva cita
   * @param nameBtnAction nombre del boton que va a salir en la alerta
   */
  async alertNotificacionSimple(data: any, redirectUrl: string = null, dataRedirectUrl: any = {}, nameBtnAction: string = 'Aceptar') {
    const alert = await this.alertController.create({
      header: data.titulo,
      message: `<p class="ion-text-left">${data.mensaje}</p>`,
      translucent: true,
      animated: true,
      mode: 'ios',
      buttons: [
        {
          text: nameBtnAction,
          handler: () => {
            if (redirectUrl) {
              // this.router.navigate([redirectUrl, dataRedirectUrl]);
              this.navController.navigateForward([redirectUrl, dataRedirectUrl]);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * @description Reenvia notificacion de cita a cliente
   * @param data id de la cita a reenviar
   */
  forwardAppointment(data) {
    this.appointmentService.getForwardAppointment(data)
      .subscribe({
        next: (response) => {
          const typeResponse = response['type'];
          if (typeResponse === this.constants.services.types.success) {
            this.presentToast(this.constants.appointment.notificationForward);
          } else if (typeResponse === this.constants.services.types.error) {
            this.alertErrorInformationWs(response, true, this.constants.contactAgendate.salesTeam.nameKeyTeam);
          }
        }, error: (err) => {
          this.statusService(err);
        }
      });
  }

  /**
   * @description Notificación tipo toast
   * @param message mensaje a mostrar
   */
  async presentToast(message: string, duration: number = 3000) {
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'middle',
      cssClass: 'toast-edit',
      mode: 'ios',
      color: 'dark'
    });
    toast.present();
  }

  /**
   * Funcion auxiliar para visualizacion del loading cuando se ejecuten eventos que
   * requieren de consultar webservis
   * @param message, para pasarle el texto mientras esta cargando
   * @param spinner, define el tipo de spinner que se va a utilizar
   */
  async presentLoadingNoLimit(message, spinner) {
    const loading = await this.loadingController.create({
      message: message,
      spinner: spinner,
      mode: 'ios'
    });
    await loading.present();
    return loading;
  }

  /**
   * @description Realiza la pantalla de espera para la carga de informacion con opciones
   */
  async presentLoadingWithOptions(message: string = 'Please wait...,', duration: number = 5000, spinner: any = null, translucent: boolean = true, cssClass: string = 'custom-class custom-loading') {
    const loading = await this.loadingController.create({
      spinner: spinner,
      duration: duration,
      message: message,
      translucent: translucent,
      cssClass: cssClass,
      mode: 'ios'
    });
    return await loading.present();
  }

  openCall(numberPhone: string) {
    if (Capacitor.getPlatform() === 'ios') {
      window.open(`tel:${numberPhone}`, '_system');
      // this.callNumber.callNumber('3166232885', true)
      //   .then(res => console.log('Launched dialer!', res))
      //   .catch(err => console.log('Error al iniciar el marcador para hacer llamadas IOS', err));
    } else {
      this.callNumber.callNumber(numberPhone, true)
        .then(res => console.log('Launched dialer!', res))
        .catch(err => console.log('Error al iniciar el marcador para hacer llamadas ANDROID', err));
    }
    // this.callNumber.callNumber(numberPhone, true).then(() => console.log('Launched dialer!'))
    //   .catch(() => console.log('Error launching dialer'));
  }

  async messageWhatsapp(numberPhone: any) {
    await Browser.open({ url: `https://wa.me/57${numberPhone}` });
    // await Browser.open({ url: `https://api.whatsapp.com/send?phone=+57${numberPhone}` });
    // window.location.href = `whatsapp://send?phone=+57${numberPhone}`;
  }

  validateUrlInactivateMenu(url) {
    if (this.constants.inactivateUrlNavigationMenu.indexOf(url) !== -1) {
      return true;
    }
    return false;
  }

  updateUserToken(token: string) {
    localStorage.setItem(this.constants.localStorage.token, token);
  }

  validateNumber(numberV: any) {
    if (!/^([0-9])*$/.test(numberV)) {
      console.log('return false');

      return false;
    } else {
      console.log('return true');

      return true;
    }
  }

  /**
   * @description pasea fechas con momment
   * @param dateParse fecha a parsear
   * @param typeParse tipo de formato a parsear
   */
  parseDates(dateParse: any, typeParse: number) {
    // .lang.('es')
    switch (typeParse) {
      case 0:
        // valida que el formato de hora sea valido devuelve true si es valido
        return moment(dateParse, 'HH:mm').isValid();
        break;
      case 1:
        const day = moment(dateParse).locale('es').format('dddd');
        const dayMonth = moment(dateParse).format('D');
        const month = moment(dateParse).locale('es').format('MMMM');
        const year = moment(dateParse).format('YYYY');
        const hour = moment(dateParse).format('h:mm A');
        return `${day}, ${dayMonth} de ${month}. ${year} - ${hour}`;
        break;
      case 2:
        // convierte la hora en formato de 12h Ejm: 03:00 PM
        return moment(dateParse, 'HH:mm').format('hh:mm a');
        break;
      case 3:
        // devuelve el dia de la semana Ejm: Mart
        return moment(dateParse).locale('es').format('ddd');
        break;
    }
  }
}


