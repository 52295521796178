import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Constants } from '../../../providers/constants/constants';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constants = Constants;

  constructor(
    public http: HttpClient,
  ) { }

  public handleError(error: HttpErrorResponse) {
    const selft = this;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
      if (error.status === 401) {
      }
    }
    // return an observable with a user-facing error message
    return throwError('Algo malo sucedio; Por favor, inténtelo de nuevo o más tarde.');
  };

  makeTokenHeader(token) {
    return token ? `Bearer ${token}` : '';
  }

  getHttpHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.makeTokenHeader(localStorage.getItem(this.constants.localStorage.token))
      })
    };
    return httpOptions;
  }

  get(path: string) {
    return this.http.get(environment.apiUrl + path, this.getHttpHeaders());
  }

  post(path: string, entity: any) {
    return this.http.post(environment.apiUrl + path, entity, this.getHttpHeaders());
    // return this.http.post(environment.apiUrl + path, entity, this.getHttpHeaders()).pipe(
    //   catchError(this.handleError) // then handle the error
    // );
  }

  put(path: string, entity: any) {
    return this.http.put(environment.apiUrl + path, entity, this.getHttpHeaders());
  }

  delete(path: string) {
    return this.http.delete(environment.apiUrl + path, this.getHttpHeaders());
  }
}
